.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
}

.login-container .logo {
    width: 250px;
    margin-bottom: 20px;
}

.disclaimer {
    font-size: 14px;
    color: #888;
    margin-bottom: 20px;
    max-width: 300px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-form input {
    padding: 10px;
    margin-bottom: 10px;
    width: 250px;
    /* Adjust the width as needed */
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.login-form button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-form button:hover {
    background-color: #ff5757;
}



.login-error .card {
    width: 500px;
    border: 1px solid #CBCBCF;
    border-radius: 12px;
    padding: 64px;
    margin-bottom: 20px;
    box-shadow: rgb(0, 0, 0, 0.08) 0px 0px 12px;
    text-align: center;
}

.login-error .message {
    font-size: 14px;
    font-weight: 400;
    color: #7B7B86;
    margin-bottom: 20px;
    text-align: center;
}

.login-error .card h1 {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 20px;
}

.login-error .btn-primary {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: red;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}

/* Button Style */
.btn-style-reset {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #696969;
    padding-top: 16px;
    font-size: 16px;
}

.btn-style-reset:hover {
    color: #000;
    text-decoration: underline;
    background-color: white;
}