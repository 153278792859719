.privilege-mg__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.privilege-mg__header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: left;
}

.privilege-mg__action-container {
    display: flex;
    align-items: center;
    flex-basis: fit-content;
    gap: 12px;
}

/* Search Style */
.privilege-search {
    height: 42px;
    width: 232px;
    padding-left: 24px;
    padding-right: 48px;
    border-radius: 100px;
    font-size: 14px;
    border: 1px solid #CBCBCF;
}

.privilege-search__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #7B7B86;
    cursor: pointer;
}

.privilege-search:focus {
    outline: none;
    box-shadow: 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

/* Filter Style */
.privilege-mg .privilege-filter {
    position: relative;
}

.privilege-mg .privilege-filter .privilege-filter__wrapper {
    position: absolute;
    padding: 20px;
    margin-top: 8px;
    right: 0;
    border: solid 1px #CBCBCF;
    border-radius: 8px;
    background-color: #ffff;
    width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.privilege-mg .privilege-filter .privilege-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.privilege-mg .privilege-filter .privilege-filter__title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex-grow: 1;
    margin: 0;
}

.privilege-mg .privilege-filter .privilege-filter__separator {
    margin: 10px 0;
    border: 0;
    height: 1px;
    background-color: #CBCBCF;
}

.privilege-mg .privilege-filter .privilege-filter__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.privilege-filter__form-row {
    display: flex;
    justify-content: space-between;
}

.privilege-filter__form-group {
    flex: 0 0 48%;
    /* Adjust width as necessary */
}

.privilege-filter__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.privilege-mg .privilege-filter .privilege-filter__close {
    background-color: white;
    border: 0;
    cursor: pointer;
}

.privilege-mg .privilege-filter .privilege-filter__footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Add privilege Style */

.privilege-mg .privilege-add__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.privilege-mg .privilege-add__modal {
    max-height: 85%;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 500px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.privilege-mg .privilege-add__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.privilege-mg .privilege-add__title {
    color: #1a1a1c;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.privilege-mg .privilege-add__separator-line {
    border-bottom: 0.5px solid #cbcbcf;
    margin-top: 10px;
    margin-bottom: 10px;
}

.privilege-mg .privilege-add__close {
    background-color: white;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.privilege-mg .privilege-add__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #1a1a1c;
}

.privilege-mg .privilege-add__form-row {
    display: flex;
    justify-content: space-between;
}

.privilege-mg .privilege-add__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.privilege-mg .privilege-add__input-required {
    color: #ed1c24;
}

.privilege-mg .privilege-add__input-box {
    width: 100%;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #CBCBCF;
    box-sizing: border-box;
}

.privilege-mg .privilege-add__input-box:focus {
    outline: none;
    box-shadow: 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

.privilege-add__confirmation-title {
    color: #1a1a1c;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
}

.privilege-add__confirmation-desc {
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.privilege-mg .privilege-add__footer {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Table Style */
.privilege-mg .privilege-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #e6e6e8;
    border-radius: 10px;
    overflow: auto;
    padding-bottom: 20px;
}

.privilege-mg .privilege-table th,
.privilege-mg .privilege-table td {
    border: none;
    padding: 16px;
    text-align: left;
}

.privilege-mg .privilege-table th {
    background-color: #F3F3F4;
    font-weight: 600;
    white-space: nowrap;
    color: #7B7B86;
}

.privilege-mg .privilege-badge--active {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #D0FBB3;
    color: #1A1A1C;
}

.privilege-mg .privilege-badge--inactive {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #CBCBCF;
    color: #1A1A1C;
}

/* Action Style */
.privilege-mg .privilege-menu {
    position: absolute;
    margin-top: 8px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    width: 145px;
    gap: 4px;
    padding: 8px;
}

.privilege-mg .privilege-menu-option {
    height: 40px;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.privilege-mg .privilege-menu-option:hover {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;

    .privilege-menu-icon {
        stroke: #ed1c24;
    }
}

.privilege-mg .privilege-menu-icon {
    stroke: #7B7B86;
    margin-right: 8px;
}

/* Form Style */
.privilege-mg .input-error {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ed1c24;
}

.privilege-mg .input-error p {
    margin: 0;
    padding: 0;
}