.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
}
.modal-overlay .modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  position: relative;
  text-align: center;
}
.modal-overlay .modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}
.modal-overlay .modal-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}
.modal-overlay .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Upload Summary Modal */
.modal-overlay.upload-summary-modal {
  z-index: 3002;
}
.modal-overlay.upload-summary-modal .upload-success-badge,
.modal-overlay.upload-summary-modal .upload-error-badge {
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}
.modal-overlay.upload-summary-modal .upload-success-badge {
  background-color: #eefde3;
  color: #49a40a;
  margin-bottom: 24px;
}
.modal-overlay.upload-summary-modal .upload-error-badge {
  background-color: #fde3e5;
  color: #f22234;
  margin-top: 24px;
  margin-bottom: 16px;
}
.modal-overlay.upload-summary-modal .upload-badge-separator {
  margin: 24px 0px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e8;
}
.modal-overlay.upload-summary-modal .upload-message {
  padding: 1rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  margin-bottom: 10px;
  text-align: start;
}
.modal-overlay.upload-summary-modal .upload-message.error {
  border: 1px solid #f22234;
  border-radius: 0.5rem;
}
.modal-overlay.upload-summary-modal .upload-message.error .message-description {
  color: #f22234;
}
.modal-overlay.upload-summary-modal
  .upload-message.error
  .upload-message-separator {
  border-top: 1px solid #f22234;
}
.modal-overlay.upload-summary-modal .upload-message .upload-message-separator {
  margin: 1rem 0;
  display: block;
  height: 1px;
  border: 0;
}
