.dashboard-mg__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.dashboard-mg__header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: left;
}

.dashboard-mg__action-container {
    display: flex;
    align-items: center;
    flex-basis: fit-content;
    gap: 12px;
}

/* Search Style */
.dashboard-search {
    height: 42px;
    width: 232px;
    padding-left: 24px;
    padding-right: 48px;
    border-radius: 100px;
    font-size: 14px;
    border: 1px solid #CBCBCF;
}

.dashboard-search__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #7B7B86;
    cursor: pointer;
}

.dashboard-search:focus {
    outline: none;
    box-shadow: 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

/* Filter Style */
.dashboard-mg .dashboard-filter {
    position: relative;
}

.dashboard-mg .dashboard-filter .dashboard-filter__wrapper {
    position: absolute;
    padding: 20px;
    margin-top: 8px;
    right: 0;
    border: solid 1px #CBCBCF;
    border-radius: 8px;
    background-color: #ffff;
    width: 560px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.dashboard-mg .dashboard-filter .dashboard-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.dashboard-mg .dashboard-filter .dashboard-filter__title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex-grow: 1;
    margin: 0;
}

.dashboard-mg .dashboard-filter .dashboard-filter__separator {
    margin: 10px 0;
    border: 0;
    height: 1px;
    background-color: #CBCBCF;
}

.dashboard-mg .dashboard-filter .dashboard-filter__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.dashboard-filter__form-row {
    display: flex;
    justify-content: space-between;
}

.dashboard-filter__form-group {
    flex: 0 0 48%;
    /* Adjust width as necessary */
}

.dashboard-filter__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.dashboard-mg .dashboard-filter .dashboard-filter__close {
    background-color: white;
    border: 0;
    cursor: pointer;
}

.dashboard-mg .dashboard-filter .dashboard-filter__footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Add dashboard Style */

.dashboard-mg .dashboard-add__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.dashboard-mg .dashboard-add__modal {
    max-height: 85%;
    overflow: auto;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 750px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.dashboard-mg .dashboard-add__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.dashboard-mg .dashboard-add__title {
    color: #1a1a1c;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.dashboard-mg .dashboard-add__separator-line {
    border-bottom: 0.5px solid #cbcbcf;
    margin-top: 10px;
    margin-bottom: 10px;
}

.dashboard-mg .dashboard-add__close {
    background-color: white;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.dashboard-mg .dashboard-add__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #1a1a1c;
    max-height: 85%;
    overflow: auto;
}

.dashboard-mg .dashboard-add__form-row {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.dashboard-mg .dashboard-add__form-group {
    width: 100%;
    /* Adjust width as necessary */
}

.dashboard-mg .dashboard-add__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.dashboard-mg .dashboard-add__input-required {
    color: #ed1c24;
}

.dashboard-mg .dashboard-add__input-box {
    width: 100%;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #CBCBCF;
    box-sizing: border-box;
}

.dashboard-mg .dashboard-add__input-box:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

.dashboard-add__confirmation-title {
    color: #1a1a1c;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
}

.dashboard-add__confirmation-desc {
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.dashboard-mg .dashboard-add__footer {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Permission Style */
.dashboard-mg .dashboard-add__permission {
    display: flex;
    border: 1px solid #CBCBCF;
    border-radius: 8px;
}

.dashboard-mg .dashboard-add__permission-sidebar {
    border-right: 1px solid #CBCBCF;
    padding: 8px;
}

.dashboard-mg .dashboard-add__permission-menus {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 150px;
}

.dashboard-mg .dashboard-add__permission-menu {
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dashboard-mg .dashboard-add__permission-menu.active {
    background-color: #FEF1F1;
}

.dashboard-mg .dashboard-add__permission-menu:hover {
    background-color: #FEF1F1;
}



.dashboard-mg .dashboard-add__permission-form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 8px;
}

.dashboard-mg .dashboard-add__permission-wrapper {
    border-radius: 8px;
    border: 1px solid #CBCBCF;
    padding-bottom: 8px;
    overflow: auto;
}

.dashboard-mg .dashboard-add__permission-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #CBCBCF;
    background-color: #F3F3F4;
}

.dashboard-mg .dashboard-add__permission-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.dashboard-mg .dashboard-add__permission-item {
    border-bottom: 1px solid #CBCBCF;
}

.dashboard-mg .dashboard-add__permission-title {
    width: 100%;
    margin: 0;
}

.dashboard-mg .dashboard-add__permission-name {
    font-size: 14px;
    margin: 0;
}

.dashboard-mg .dashboard-add__permission-input {
    width: 100%;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #CBCBCF;
    box-sizing: border-box;
}

.dashboard-mg .dashboard-add__permission-input:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

/* Table Style */
.dashboard-mg .dashboard-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #e6e6e8;
    border-radius: 10px;
    overflow: auto;
    padding-bottom: 20px;
}

.dashboard-mg .dashboard-table th,
.dashboard-mg .dashboard-table td {
    border: none;
    padding: 16px;
    text-align: left;
}

.dashboard-mg .dashboard-table th {
    background-color: #F3F3F4;
    font-weight: 600;
    white-space: nowrap;
    color: #7B7B86;
}

.dashboard-mg .dashboard-badge--active {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #D0FBB3;
    color: #1A1A1C;
}

.dashboard-mg .dashboard-badge--inactive {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #CBCBCF;
    color: #1A1A1C;
}

/* Action Style */
.dashboard-mg .dashboard-menu {
    position: absolute;
    margin-top: 8px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    width: 145px;
    gap: 4px;
    padding: 8px;
}

.dashboard-mg .dashboard-menu-option {
    height: 40px;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-mg .dashboard-menu-option:hover {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;

    .dashboard-menu-icon {
        stroke: #ed1c24;
    }
}

.dashboard-mg .dashboard-menu-icon {
    stroke: #7B7B86;
    margin-right: 8px;
}

/* Form Style */
.dashboard-mg .input-error {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ed1c24;
}

.dashboard-mg .input-error p {
    margin: 0;
    padding: 0;
}