.detail-header {
  display: flex;
  gap: 8px;
  margin: 0;
  font-weight: 600;
  line-height: 32px;
  font-size: 24px;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.detail-content {
  border: 1px solid #E6E6E8;
  padding: 24px;
  gap: 24px;
  border-radius: 12px;
}

.fallback-overview {
  padding: 20px;
}

.content-header {
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: #1a1a1a;
}

.box-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.box {
  width: calc(20% - 16px);
  padding: 16px;
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  box-sizing: border-box;
  text-align: left;
}

.box-container .label {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #1a1a1a;
}

.box-container .value {
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  color: #7B7B86;
}

.status-box {
  padding: 2px 12px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  display: inline-block;
}

.status-box.success {
  background-color: #d0fbb3;
  color: #1e4404;
}

.status-box.error {
  background-color: #fbb3b9;
  color: #44040a;
}

.detail-issue {
  margin-top: 32px;
}

.issue-content {
  display: flex;
  gap: 16px;
}

.issue-box {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  padding: 16px;
  flex: 1;
  min-width: 0;
}

.issue-box .label {
  margin: 0;
  font-weight: 600;
  line-height: 24px;
  font-size: 16px;
  color: #7B7B86;
}

.issue-box .value {
  margin: 0;
  font-weight: 400;
  line-height: 24px;
  font-size: 14px;
  color: #7B7B86;
}

.issue-box .separator {
  height: 1px;
  width: 100%;
  background-color: #E6E6E8;
  margin: 16px 0;
}

.detail-history {
  margin-top: 24px;
}

.closing-rating {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.history-box {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  width: 145px;
  height: 60px;
  margin: 0;
  gap: 12px;
}

.history-box .box-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1a1a1a;
  margin: 0;
}

.history-box .box-value {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
  margin: 0;
}

.history-table-container {
  overflow: auto;
  border-radius: 8px;
  border: 1px solid #e6e6e8;
  padding-bottom: 8px;
}

.history-table {
  border-collapse: collapse;
  width: 100%;
  border: none;
}

.history-table th,
.history-table td {
  padding: 16px;
  border-bottom: 1px solid #E6E6E8;
  text-align: left;
}

.history-table th {
  background-color: #F3F3F4;
  font-weight: 600;
}

/* .history-table tr:nth-child(even) {
  background-color: #f9f9f9;
} */

.analytics-metrics {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.metrics-box {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  width: 191px;
  /* height: 116px; */
  margin: 0;
  gap: 12px;
  padding-bottom: 15px;
}

.metrics-box .box-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
  margin: 0;
  padding-top: 16px;
  padding-left: 16px;
}

.metrics-box .box-value {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #1a1a1a;
  margin: 0;
  padding-left: 16px;
}

.metrics-box .box-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
  margin: 0;
  padding-left: 16px;
}

/* Custome */
.show-more:hover {
  color: #1a1a1a;
  text-decoration: underline;
  cursor: default;
}