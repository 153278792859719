.bulk-testing__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
}

.bulk-testing__header-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
  text-align: left;
}

.bulk-testing__action-container {
  display: flex;
  align-items: center;
  flex-basis: fit-content;
  gap: 12px;
}

/* Search Style */
.bulk-search {
  height: 48px;
  width: 232px;
  padding-left: 24px;
  padding-right: 48px;
  border-radius: 100px;
  font-size: 14px;
  border: 1px solid #CBCBCF;
}

.bulk-search__icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #7B7B86;
  cursor: pointer;
}

.bulk-search:focus {
  outline: none;
  box-shadow: 0 0 0 1px #ed1c24;
  border: 1px solid #ed1c24;
}

/* Filter Style */
.bulk-testing .bulk-filter {
  position: relative;
}

.bulk-testing .bulk-filter .bulk-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 540px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .bulk-filter .bulk-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .bulk-filter .bulk-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.bulk-testing .bulk-filter .bulk-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.bulk-testing .bulk-filter .bulk-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.bulk-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.bulk-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.bulk-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.bulk-testing .bulk-filter .bulk-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.bulk-testing .bulk-filter .bulk-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Add bulk Style */

.bulk-testing .bulk-add__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-testing .bulk-add__modal {
  overflow: auto;
  max-height: 80%;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .bulk-add__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .bulk-add__title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.bulk-testing .bulk-add__separator-line {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bulk-testing .bulk-add__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bulk-testing .bulk-add__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bulk-testing .bulk-add__form-row {
  display: flex;
  justify-content: space-between;
}

.bulk-testing .bulk-add__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.bulk-testing .bulk-add__input-required {
  color: #ed1c24;
}

.bulk-testing .bulk-add__input-box {
  width: 100%;
  padding: 10px 12px 10px 12px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #CBCBCF;
  box-sizing: border-box;
}

.bulk-testing .bulk-add__input-box:disabled {
  background-color: #e6e6e8;
  cursor: not-allowed;
}

.bulk-testing .bulk-add__input-box.error {
  border: 1px solid #ed1c24;
}

.bulk-testing .bulk-add__input-box:focus {
  outline: none;
  box-shadow: 0 0 0 1px #ed1c24;
  border: 1px solid #ed1c24;
}

.bulk-testing .bulk-add__input-sub-label {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #7B7B86;
}

.bulk-testing .bulk-add__file-upload {
  display: none;
}

.bulk-testing .bulk-add__file-upload-btn {
  height: 32px;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  color: #1a1a1c;
  border: 1px solid #CBCBCF;
  cursor: pointer;
  background-color: white;
}

.bulk-testing .bulk-add__file-upload-btn.with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.bulk-testing .bulk-add__file-item {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: fit-content;
}

.bulk-testing .bulk-add__file-name {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1c;
  margin: 0;
  padding: 0;
}

.bulk-testing .bulk-add__file-size {
  font-size: 14px;
  font-weight: 500;
  color: #7B7B86;
  margin: 0;
  padding: 0;
}

.bulk-testing .bulk-add__file-upload-icon {
  color: #1A1A1C;
  width: 20px;
  height: 20px;
}

.bulk-testing .bulk-add__file-upload-btn:hover {
  background-color: #F3F3F4;
}

.bulk-testing .bulk-add__file-upload-btn:disabled {
  color: #CBCBCF;
  cursor: not-allowed;

  .bulk-testing .bulk-add__file-upload-icon {
    color: #CBCBCF;
  }
}



.bulk-add__confirmation-title {
  color: #1a1a1c;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 8px;
}

.bulk-add__confirmation-desc {
  color: #8f8f8f;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 0;
}

.bulk-testing .bulk-add__footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Table Style */
.bulk-testing .bulk-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  overflow: auto;
  padding-bottom: 20px;
}

.bulk-testing .bulk-table th,
.bulk-testing .bulk-table td {
  border: none;
  padding: 16px;
  text-align: left;
}

.bulk-testing .bulk-table th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.bulk-testing .bulk-badge--active {
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  background-color: #D0FBB3;
  color: #1A1A1C;
}

.bulk-testing .bulk-badge--inactive {
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  background-color: #CBCBCF;
  color: #1A1A1C;
}

/* Action Style */
.bulk-testing .bulk-menu {
  position: absolute;
  margin-top: 8px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  width: 200px;
  gap: 4px;
  padding: 8px;
}

.bulk-testing .bulk-menu-option {
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.bulk-testing .bulk-menu-option[aria-disabled="true"] {
  color: #CBCBCF;
  cursor: not-allowed;
}

.bulk-testing .bulk-menu-option:hover {
  background-color: rgba(237, 28, 36, 0.1);
  color: #ed1c24;

  .bulk-menu-icon {
    stroke: #ed1c24;
  }
}

.bulk-testing .bulk-menu-icon {
  stroke: #7B7B86;
  margin-right: 8px;
}

/* Form Style */
.bulk-testing .input-error {
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ed1c24;
}

.bulk-testing .input-error p {
  margin: 0;
  padding: 0;
}

/* Info Box Active Configuraiton */
.bulk-testing .bulk-active-box {
  padding: 16px;
  margin-bottom: 1rem;
  border-radius: 8px;
}

.bulk-testing .bulk-active-group {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bulk-testing .bulk-active--info {
  background-color: #E2EBFF;
  color: #1661FF;
}

.bulk-testing .bulk-active-group p,
.bulk-testing .bulk-active-group h5 {
  margin: 0;
  padding: 0;
}

.bulk-testing-detail {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bulk-testing-detail .bulk-detail__title {
  font-weight: 500;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.bulk-testing-detail .bulk-detail__boxes {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);
}

.bulk-testing-detail .actual-detail__boxes {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
}

.bulk-testing-detail .bulk-detail__box {
  padding: 16px;
  border: 1px solid #CBCBCF;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.bulk-testing-detail .bulk-detail__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bulk-testing-detail .bulk-detail__box>h5,
.bulk-testing-detail .bulk-detail__box>p {
  margin: 0;
  padding: 0;
}

.bulk-testing-detail .bulk-detail__box>h5 {
  color: #1A1A1C;
  font-weight: 400;
}

.bulk-testing-detail .bulk-detail__box>p {
  color: #7B7B86;
  font-weight: 400;
}

.bulk-testing-detail .bulk-detail__separator {
  border-bottom: 1px solid #CBCBCF;
}

/* Apply Configuration Modal */
.bulk-testing .apply-conf__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-testing .apply-conf__modal {
  overflow: auto;
  max-height: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 580px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .apply-conf__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .apply-conf__header-title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.bulk-testing .apply-conf__separator {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bulk-testing .apply-conf__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bulk-testing .apply-conf__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bulk-testing .apply-conf__content-title {
  margin-top: 20px;
  margin-bottom: 0;
  color: #1a1a1c;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  width: 100%;
}

.bulk-testing .apply-conf__content-subtitle {
  margin-top: 20px;
  margin-bottom: 0;
  color: #7B7B86;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.bulk-testing .apply-conf__footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.bulk-testing .apply-conf__active-box {
  padding: 16px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #CBCBCF;
  background-color: #F3F3F4
}

.bulk-testing .apply-conf__boxes {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.bulk-testing .apply-conf__box {
  padding: 16px;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid #CBCBCF;
  background-color: white;
}

.bulk-testing .apply-conf__active-box-title {
  margin-top: 0;
  padding: 0;
  text-align: center;
}


.bulk-testing .apply-conf__box>h5,
.bulk-testing .apply-conf__box>p {
  margin: 0;
  padding: 0;
}

/* Bulk Detail Bot Answer Modal */
/* Apply Configuration Modal */
.bulk-testing .bot-ans__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-testing .bot-ans__modal {
  overflow: auto;
  max-height: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  min-width: 580px;
  max-width: 75%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .bot-ans__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .bot-ans__header-title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.bulk-testing .bot-ans__separator {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bulk-testing .bot-ans__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bulk-testing .bot-ans__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bulk-testing .bot-ans__content-title {
  margin-top: 20px;
  margin-bottom: 0;
  color: #1a1a1c;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  width: 100%;
}

.bulk-testing .bot-ans__content-subtitle {
  margin-top: 20px;
  margin-bottom: 0;
  color: #7B7B86;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.bulk-testing .bot-ans__footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Bot Answer Markdown Style */
.bot-ans__markdown-style {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bot-ans__markdown-style>p {
  margin: 0;
  padding: 0;
}

.bot-ans__markdown-style>table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  overflow: auto;
  padding-bottom: 20px;
}

.bot-ans__markdown-style>table>thead>tr>th,
.bot-ans__markdown-style>table>tbody>tr>td {
  border: none;
  padding: 8px;
  text-align: left;
}

.bot-ans__markdown-style>table>thead>tr>th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

/* Execute Bulk Test Modal */
.bulk-testing .execute-bulk__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-testing .execute-bulk__modal {
  overflow: auto;
  max-height: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 580px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .execute-bulk__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .execute-bulk__header-title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.bulk-testing .execute-bulk__separator {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bulk-testing .execute-bulk__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bulk-testing .execute-bulk__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bulk-testing .execute-bulk__content-title {
  margin-top: 20px;
  margin-bottom: 0;
  color: #1a1a1c;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  width: 100%;
}

.bulk-testing .execute-bulk__content-subtitle {
  margin-top: 0;
  margin-bottom: 0;
  color: #7B7B86;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.bulk-testing .execute-bulk__footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Bulk Detail Query Result Modal */
/* Apply Configuration Modal */
.bulk-testing .query-result__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.bulk-testing .query-result__modal {
  overflow: auto;
  max-height: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  min-width: 580px;
  max-width: 75%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.bulk-testing .query-result__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.bulk-testing .query-result__header-title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.bulk-testing .query-result__separator {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bulk-testing .query-result__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.bulk-testing .query-result__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
}

.bulk-testing .query-result__content-title {
  margin-top: 20px;
  margin-bottom: 0;
  color: #1a1a1c;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  width: 100%;
}

.bulk-testing .query-result__content-subtitle {
  margin-top: 20px;
  margin-bottom: 0;
  color: #7B7B86;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  width: 100%;
}

.bulk-testing .query-result__footer {
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.bulk-testing .query-result__sql {
  background-color: #282828;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-radius: 8px;
}
.bulk-testing .query-result__sql-styled {
  white-space: pre;
  color: white;
  margin: 0;
  padding: 0;
}

.bulk-testing .query-result__copy {
  padding-top: 16px;
  display: flex;
  justify-content: end;
}