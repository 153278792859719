.functions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.functions-header h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.upload-button {
  display: flex;
  align-items: center;
  width: 227px;
  height: 48px;
  padding: 12px 24px 12px 12px;
  gap: 10px;
  border: none;
  border-radius: 100px;
  background: #ed1c24;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.download-button,
.filter-button {
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  background-color: #fff;
  color: #1a1a1c;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
}

.functions-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 16px;
}

.functions-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  margin-top: 16px;
  overflow: auto;
  padding-bottom: 20px;
}

.functions-table th,
.functions-table td {
  border: none;
  padding: 16px;
  text-align: left;
}

.functions-table th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.functions-menu {
  position: absolute;
  margin-top: 8px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  height: 108px;
  width: 145px;
  gap: 4px;
  padding: 8px;
}

.functions-menu-option {
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.functions-menu-option:hover {
  background-color: rgba(237, 28, 36, 0.1);
  color: #ed1c24;

  .functions-menu-icon {
    stroke: #ed1c24;
  }
}

.functions-menu-icon {
  stroke: #7B7B86;
  margin-right: 8px;
}

.functions.filter-modal {
  position: relative;
  top: 21%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  left: 32%;
}

.select__control--is-focused {
  border-color: #ed1c24 !important;
  box-shadow: 0 0 0 1px #ed1c24 !important;
}

.select__option--is-selected {
  background-color: #ed1c24 !important;
  color: white !important;
}

.select__option--is-focused {
  background-color: #ffcccc !important;
}

.select__multi-value {
  background-color: #ed1c24 !important;
}

.select__multi-value__label {
  color: white !important;
}

.select__multi-value__remove:hover {
  background-color: darkred !important;
  color: white !important;
}

.download-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}

.download-modal.open {
  display: flex;
}

.download-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 400px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.download-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.download-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.file-download-section {
  margin: 20px 0;
}

.file-download-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.file-name {
  color: #2e2eff;
}

.download-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.download-all-section {
  margin-top: 20px;
}

.download-all-button {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  border-radius: 100px;
  background-color: white;
  border: 1px solid #cbcbcf;
  color: #1a1a1c;
}

/* .download-all-button:hover {
  background-color: #ffcccc;
} */

.upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
}

.upload-modal-overlay .upload-success-badge,
.upload-modal-overlay .upload-error-badge {
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
}

.upload-modal-overlay .upload-success-badge {
  background-color: #eefde3;
  color: #49a40a;
  margin-bottom: 24px;
}

.upload-modal-overlay .upload-error-badge {
  background-color: #fde3e5;
  color: #f22234;
  margin-top: 24px;
  margin-bottom: 16px;
}

.upload-modal-overlay .upload-badge-separator {
  margin: 24px 0px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e8;
}

.upload-modal-overlay .upload-message {
  padding: 1rem;
  font-size: 1rem;
  line-height: calc(1rem * 1.5);
  margin-bottom: 10px;
  text-align: start;
}

.upload-modal-overlay .upload-message.error {
  border: 1px solid #f22234;
  border-radius: 0.5rem;
}

.upload-modal-overlay .upload-message.error .message-description {
  color: #f22234;
}

.upload-modal-overlay .upload-message.error .upload-message-separator {
  border-top: 1px solid #f22234;
}

.upload-modal-overlay .upload-message .upload-message-separator {
  margin: 1rem 0;
  display: block;
  height: 1px;
  border: 0;
}

.upload-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  position: relative;
  text-align: center;
}

.upload-modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-modal-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.file-upload-section {
  margin-top: 20px;
  text-align: left;
}

.file-upload-label {
  font-size: 16px;
  font-weight: 500;
}

.file-upload-input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.file-upload-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-upload-hidden-input {
  display: none;
}

.file-upload-button {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #ed1c24;
  color: white;
  border: none;
  border-radius: 4px;
}

.file-upload-warning {
  color: #7B7B86;
  font-size: 12px;
  margin-top: 5px;
}

.function-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.back-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.function-details-title {
  margin: 0;
  font-size: 24px;
  flex-grow: 1;
  color: #1a1a1c;
  font-weight: 500;
}

.function-edit-button {
  width: 142px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  cursor: pointer;
  background-color: #ed1c24;
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border: none;
}

.function-overview-container,
.sample-questions-container {
  border: 1px solid #e6e6e8;
  padding: 24px;
  border-radius: 12px;
  gap: 24px;
}

.function-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.overview-title,
.sample-questions-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #1a1a1c;
  margin: 0;
  margin-bottom: 16px;
}

.overview-box {
  border: 1px solid #e6e6e8;
  border-radius: 8px;
  padding: 16px;
  gap: 16px;
  margin-bottom: 10px;
}

.overview-box.function-name {
  height: 104px;
}

.overview-box-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #1a1a1c;
}

.overview-box .overview-separator {
  border: 1px solid #e6e6e8;
}

.overview-box .overview-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.sample-questions-container {
  margin-top: 20px;
}

.sample-questions-list {
  list-style-type: none;
  padding: 0;
}

.sample-questions-list li {
  background: #ffffff;
  color: #7B7B86;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
  padding: 16px 12px;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.edit-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.edit-modal.open {
  display: flex;
}

.edit-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.edit-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.edit-separator {
  border: none;
  border-top: 1px solid #CBCBCF;
  margin-bottom: 20px;
}

.edit-section {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #e6e6e8;
  gap: 24px;
  max-height: 600px;
  overflow: auto;
}

@media only screen and (min-height: 968px) {
  .edit-section {
    max-height: 700px;
  }
}

@media only screen and (min-height: 1080px) {
  .edit-section {
    max-height: 800px;
  }
}


.edit-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-name {
  font-size: 16px;
  font-weight: 500;
}

.edit-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-sample-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.edit-sample-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.edit-sample-item .edit-input {
  width: 100%;
}

.add-more-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
}

.add-more-button {
  color: #1a1a1c;
  background-color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 101px;
  height: 36px;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
}

.add-more-container .add-more-button:hover {
  background-color: #e6e6e8;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.trash-icon {
  width: 16px;
  height: 16px;
}

.confirmation-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.confirmation-subtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7b7b86;
}

/* Filter Style */
.functions .functions-filter {
  position: relative;
}

.functions .functions-filter .functions-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 1;
}

.functions .functions-filter .functions-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.functions .functions-filter .functions-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.functions .functions-filter .functions-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.functions .functions-filter .functions-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.functions-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.functions-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.functions-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.functions .functions-filter .functions-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.functions .functions-filter .functions-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
