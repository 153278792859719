.role-mg__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.role-mg__header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: left;
}

.role-mg__action-container {
    display: flex;
    align-items: center;
    flex-basis: fit-content;
    gap: 12px;
}

/* Search Style */
.role-search {
    height: 42px;
    width: 232px;
    padding-left: 24px;
    padding-right: 48px;
    border-radius: 100px;
    font-size: 14px;
    border: 1px solid #CBCBCF;
}

.role-search__icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #7B7B86;
    cursor: pointer;
}

.role-search:focus {
    outline: none;
    box-shadow: 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

/* Filter Style */
.role-mg .role-filter {
    position: relative;
}

.role-mg .role-filter .role-filter__wrapper {
    position: absolute;
    padding: 20px;
    margin-top: 8px;
    right: 0;
    border: solid 1px #CBCBCF;
    border-radius: 8px;
    background-color: #ffff;
    width: 560px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.role-mg .role-filter .role-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.role-mg .role-filter .role-filter__title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex-grow: 1;
    margin: 0;
}

.role-mg .role-filter .role-filter__separator {
    margin: 10px 0;
    border: 0;
    height: 1px;
    background-color: #CBCBCF;
}

.role-mg .role-filter .role-filter__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.role-filter__form-row {
    display: flex;
    justify-content: space-between;
}

.role-filter__form-group {
    flex: 0 0 48%;
    /* Adjust width as necessary */
}

.role-filter__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.role-mg .role-filter .role-filter__close {
    background-color: white;
    border: 0;
    cursor: pointer;
}

.role-mg .role-filter .role-filter__footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Add role Style */

.role-mg .role-add__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.role-mg .role-add__modal {
    max-height: 85%;
    overflow: auto;
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 500px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.role-mg .role-add__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.role-mg .role-add__title {
    color: #1a1a1c;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    width: 100%;
    margin: 0;
}

.role-mg .role-add__separator-line {
    border-bottom: 0.5px solid #cbcbcf;
    margin-top: 10px;
    margin-bottom: 10px;
}

.role-mg .role-add__close {
    background-color: white;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.role-mg .role-add__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    color: #1a1a1c;
}

.role-mg .role-add__form-row {
    display: flex;
    justify-content: space-between;
}

.role-mg .role-add__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.role-mg .role-add__input-required {
    color: #ed1c24;
}

.role-mg .role-add__input-box {
    width: 100%;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
    font-size: 16px;
    border: 1px solid #CBCBCF;
    box-sizing: border-box;
}

.role-mg .role-add__input-box:focus {
    outline: none;
    box-shadow: 0 0 0 1px #ed1c24;
    border: 1px solid #ed1c24;
}

.role-add__confirmation-title {
    color: #1a1a1c;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 8px;
}

.role-add__confirmation-desc {
    color: #8f8f8f;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 0;
}

.role-mg .role-add__footer {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

/* Table Style */
.role-mg .role-table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #e6e6e8;
    border-radius: 10px;
    overflow: auto;
    padding-bottom: 20px;
}

.role-mg .role-table th,
.role-mg .role-table td {
    border: none;
    padding: 16px;
    text-align: left;
}

.role-mg .role-table th {
    background-color: #F3F3F4;
    font-weight: 600;
    white-space: nowrap;
    color: #7B7B86;
}

.role-mg .role-badge--active {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #D0FBB3;
    color: #1A1A1C;
}

.role-mg .role-badge--inactive {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #CBCBCF;
    color: #1A1A1C;
}

/* Action Style */
.role-mg .role-menu {
    position: absolute;
    margin-top: 8px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1;
    width: 145px;
    gap: 4px;
    padding: 8px;
}

.role-mg .role-menu-option {
    height: 40px;
    border-radius: 8px;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.role-mg .role-menu-option:hover {
    background-color: rgba(237, 28, 36, 0.1);
    color: #ed1c24;

    .role-menu-icon {
        stroke: #ed1c24;
    }
}

.role-mg .role-menu-icon {
    stroke: #7B7B86;
    margin-right: 8px;
}

/* Form Validation Style */
.role-mg .input-error {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #ed1c24;
}

.role-mg .input-error p {
    margin: 0;
    padding: 0;
}

.role-mg .role-badge--active {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #D0FBB3;
    color: #1A1A1C;
}

.role-mg .role-badge--inactive {
    font-size: 14px;
    padding: 4px 12px 4px 12px;
    border-radius: 4px;
    background-color: #CBCBCF;
    color: #1A1A1C;
}

/* Form Accordian Style */
.role-mg .role-add__accordian {
    border: 1px solid #CBCBCF;
    border-radius: 8px;
    overflow: hidden;
}

.role-mg .role-add__accordian-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms ease;
}

.role-mg .role-add__accordian-content {
    overflow: hidden;
}

.role-mg .role-add__accordian-wrapper.show {
    grid-template-rows: 1fr;
}

.role-mg .role-add__accordian-separator {
    border-bottom: 1px solid #CBCBCF;
}

.role-mg .role-add__accordian-separator:last-child {
    border-bottom: none;
}

.role-mg .role-add__accordian-header {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.role-mg .role-add__accordian-title {
    flex-grow: 1;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
}

.role-mg .role-add__accordian-icon {
    transition: transform 0.3s ease;
}

.role-mg .role-add__accordian-icon.rotate {
    transform: rotate(-180deg);
}

.role-mg .role-add__accordian-content.show {
    display: block;
}

.role-mg .role-add__accordian-search-wrapper {
    position: relative;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.role-mg .role-add__accordian-search {
    width: 100%;
    box-sizing: border-box;
    height: 32px;
    padding-left: 16px;
    padding-right: 28px;
    font-size: 12px;
    border: 1px solid #CBCBCF;
    border-radius: 100px;
}

.role-mg .role-add__accordian-search-icon {
    position: absolute;
    right: 28px;
    top: calc(50%);
    transform: translateY(-50%);
    color: #CBCBCF;
    cursor: pointer;
}

.role-mg .role-add__accordian-search:focus {
    outline: none;
    border: 1px solid #ed1c24;
}


.role-mg .role-add__accordian-list {
    list-style-type: none;
    margin: 0;
    max-height: 500px;
    overflow: auto;
}

.role-mg .role-add__accordian-list li {
    margin-bottom: 8px;
    display: flex;
    gap: 16px;
}

/* Form Checkbox */
.role-mg .role-add__form-checkbox {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.role-mg .role-add__form-checkbox input {
    display: none;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.role-mg .role-add__checkmark {
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 1px solid #cbcbcf;
    border-radius: 4px;
}

.role-mg .role-add__form-checkbox:hover input~.role-add__checkmark {
    background-color: #cbcbcf;
    border: 1px solid #cbcbcf;
}

.role-mg .role-add__form-checkbox input:checked~.role-add__checkmark {
    background-color: #ED1C24;
}

.role-mg .role-add__form-checkbox .role-add__checkmark.show {
    background-color: #ED1C24;
}

.role-mg .role-add__form-checkbox input:disabled~.role-add__checkmark {
    background-color: #cbcbcf;
}

.role-mg .role-add__checkmark-icon {
    display: none;
}

.role-mg .role-add__form-checkbox input:checked~.role-add__checkmark .role-add__checkmark-icon {
    display: block;
    color: #fff;
}

.role-mg .role-add__form-checkbox .role-add__checkmark .role-add__checkmark-icon.show {
    display: block;
    color: #fff;
}

.role-mg .role-add__form-checkbox input:checked~.role-add__checkmark .role-add__checkmark-icon--red {
    color: #1A1A1C;
}