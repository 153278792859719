.rq-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.rq-header-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.tooltip-icon {
  margin-left: 8px;
}

.redirect-icon {
  margin-left: auto;
  width: 16px;
  height: 16px;
}

/* Section Style */
.rq-section-chart-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

@media only screen and (max-width: 1080px) {
  .rq-section-chart-container {
    display: block;
  }
}


.rq-section-container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.rq-section-container .grow {
  flex-grow: 1;
}

.rq-section-container-grid {
  display: grid;
  max-width: calc(100%);
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.rq-section {
  border: 1px solid #e6e6e8;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.rq-section .section-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #1a1a1c;
  margin-bottom: 24px;
}

.rq-section.w-400 {
  width: 400px;
}

/* Boxes Style */
.rq-boxes {
  display: grid;
  grid-template-columns: repeat(5, minmax(100px, 1fr));
  gap: 8px;
}

@media only screen and (max-width: 1080px) {
  .rq-boxes {
    grid-template-columns: repeat(3, minmax(100px, 1fr));
  }

}

.rq-box {
  margin: 0;
  border: 1px solid #e6e6e8;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  gap: 12px;
  align-content: center;
  width: 100%;
  box-sizing: border-box;
}

.rq-box.w-280 {
  width: 280px;
}

.rq-box .box-title {
  font-size: 12px;
  line-height: 16px;
  color: #1a1a1c;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.rq-box .box-value {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  vertical-align: top;
  color: #1a1a1c;
  margin: 0;
}

.rq-box .box-sub-value {
  font-size: 16px;
  color: #7B7B86;
}

.rq-box .box-date {
  margin: 0;
  color: #7b7b86;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.rq-select-form {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #e6e6e8;
}

.rq-chart-and-legend {
  display: flex;
  align-items: center;
}

.rq-legend-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.rq-legend-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.rq-legend-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.rq-legend-color-box {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 3px;
}

.rq-value,
.rq-percentage-bar,
.rq-percentage-text {
  display: inline-block;
  vertical-align: middle;
}

.rq-value {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
}

.rq-percentage-bar {
  width: 100px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.rq-percentage-fill {
  height: 100%;
  border-radius: 5px;
}

.rq-percentage-text {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
}

.rq-function-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #E6E6E8;
  border-radius: 12px;
  overflow: auto;
  padding-bottom: 20px;
}

.rq-function-table th,
.rq-function-table td {
  border: none;
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #E6E6E8;
}

.rq-function-table th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.rq.filter-modal {
  position: relative;
  top: 13%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  left: 26%;
}

/* Filter Modal Style */
.response-quality .rq-filter {
  position: relative;
}

.response-quality .rq-filter .rq-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 540px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 3000;
}

.response-quality .rq-filter .rq-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.response-quality .rq-filter .rq-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.response-quality .rq-filter .rq-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.response-quality .rq-filter .rq-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.rq-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.rq-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.rq-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.response-quality .rq-filter .rq-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.response-quality .rq-filter .rq-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}


/* User Rating Style */
.rq-user-rating {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}

.rq-user-rating.open {
  display: flex;
}

.rq-user-rating-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  /* height: 700px; */
  width: 1200px;
  max-height: 600px;
  text-align: center;
  overflow: auto;
}

.rq-user-rating-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rq-user-rating-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

.rq-rating-close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.rq-rating-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.rq-rating-summary {
  display: flex;
  gap: 48px;
  align-items: center;
  justify-content: center;
}

.rq-rating-number {
  text-align: center;
}

.rq-rating-number h2 {
  margin: 0;
  font-size: 96px;
  font-weight: 600;
  line-height: 96px;
  text-align: left;
  color: #ED1C24;
}

.rq-rating-number p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7B7B86;
  margin: 0;
}

.rq-rating-star {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #1A1A1C;
}

.rq-rating-star-number {
  flex-grow: 1;
}

.rq-rating-bar {
  display: flex;
  align-items: center;
}

.rq-rating-breakdown {
  display: flex;
  flex-flow: column;
  gap: 4px;
}

.rq-bar {
  width: 350px;
  height: 10px;
  background-color: #FCD9DA;
  margin: 0 10px;
  border-radius: 5px;
}

.rq-bar-fill {
  height: 100%;
  background-color: #ED1C24;
  border-radius: 5px;
}

.rq-user-rating-section {
  margin-top: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  border-radius: 12px;
  border: 1px solid #E6E6E8;
  overflow: auto;
}

.rq-user-rating-table {
  height: auto;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  overflow-x: scroll;
  text-wrap: wrap;
}

.rq-user-rating-table th,
.rq-user-rating-table td {
  padding: 16px;
  border-bottom: 1px solid #E6E6E8;
  text-align: left;
  height: auto;
}

.rq-user-rating-table th {
  background-color: #F3F3F4;
  font-weight: 600;
}

.rq-user-rating-pagination {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 10px;
  padding-right: 10px;
}

.rq-user-rating-pagination button {
  width: auto;
  height: 36px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
}


/* User Satisfaction Style */
.rq-user-satisfaction {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}

.rq-user-satisfaction.open {
  display: flex;
}

.rq-user-satisfaction-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 1200px;
  text-align: center;
}

.rq-user-satisfaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.rq-user-satisfaction-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  flex-grow: 1;
  text-align: center;
  margin: 0;
}

.rq-user-satisfaction-close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.rq-user-satisfaction-section {
  padding-right: 8px;
  max-height: 80%;
  overflow: auto;
}

.rq-user-satisfaction-box {
  border: 1px solid #E6E6E8;
  border-radius: 8px;
  padding-bottom: 10px;
  overflow: hidden;
}

.rq-user-satisfaction-table {
  height: auto;
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  overflow-x: scroll;
  text-wrap: wrap;
}

.rq-user-satisfaction-table th,
.rq-user-satisfaction-table td {
  padding: 16px;
  border-bottom: 1px solid #E6E6E8;
  text-align: left;
  height: auto;
}

.rq-user-satisfaction-table th {
  background-color: #F3F3F4;
  font-weight: 600;
}

.rq-user-satisfaction-pagination {
  display: flex;
  justify-content: right;
  gap: 10px;
  margin-top: 10px;
  padding-right: 10px;
}

.rq-user-satisfaction-pagination button {
  width: auto;
  height: 36px;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
}

.rq-user-satisfaction-filter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.rq-user-satisfaction-button {
  padding: 10px 20px;
  background-color: #fff;
  width: 97px;
  height: 48px;
  padding: 12px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.rq-kebab-menu {
  position: absolute;
  right: 0;
  top: 100%;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  height: 35px;
  width: 145px;
  gap: 4px;
  padding: 8px;
}

.rq-kebab-menu-option {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 12px;
}

.rq-kebab-menu-option:hover {
  background-color: rgba(237, 28, 36, 0.1);
  color: #ed1c24;
}

.rq-kebab-menu-icon {
  stroke: #ed1c24;
}


.rq-kebab-menu-icon {
  stroke: #7B7B86;
  margin-right: 8px;
}

.rq-user-satisfaction-details h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #1A1A1C;
}

.rq-positive-rate {
  color: #5ED40D;
}

.rq-negative-rate {
  color: #D40D1E;
}