.tcc-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tcc-header-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.tcc-button-group {
  display: flex;
}

/* Section Style */
.tcc-section-container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.tcc-section-chart-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

@media only screen and (max-width: 1080px) {
  .tcc-section-chart-container {
    display: block;
  }
}

.tcc-section-container .grow {
  flex-grow: 1;
}

.tcc-section {
  border: 1px solid #e6e6e8;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 20px;
}

.tcc-section .section-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  color: #1a1a1c;
  margin-bottom: 24px;
}

.tcc-section.w-400 {
  width: 400px;
}

.tcc-chart-container {
  width: 100%;
}

/* Input Style */
.tcc-select-form {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #e6e6e8;
}

/* Boxes Style */

.tcc-boxes {
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, auto));
  gap: 16px;
}

@media only screen and (max-width: 1080px) {
  .tcc-boxes {
      grid-template-columns: repeat(2, minmax(100px, auto));
  }
}

.tcc-box {
  margin: 0;
  border: 1px solid #e6e6e8;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  gap: 12px;
  align-content: center;
}

.tcc-box.w-280 {
  width: 280px;
}

.tcc-box .box-title {
  font-size: 12px;
  line-height: 16px;
  color: #1a1a1c;
  margin: 0;
  display: inline-flex;
  align-items: center;
}

.tcc-box .box-value {
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  vertical-align: top;
  color: #1a1a1c;
  margin: 0;
}

.tcc-box .box-sub-value {
  font-size: 16px;
  color: #7B7B86;
}

.tcc-box .box-date {
  margin: 0;
  color: #7b7b86;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

/* Filter Modal Style */
.tcc .tcc-filter {
  position: relative;
}

.tcc .tcc-filter .tcc-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 540px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 3000;
}

.tcc .tcc-filter .tcc-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.tcc .tcc-filter .tcc-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.tcc .tcc-filter .tcc-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.tcc .tcc-filter .tcc-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.tcc-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.tcc-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.tcc-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.tcc .tcc-filter .tcc-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.tcc .tcc-filter .tcc-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Chart Style */

.tcc-charts-section {
  display: flex;
  margin-bottom: 20px;
  gap: 24px;
}

.tcc-line-chart {
  flex: 1;
  padding: 24px;
  padding-bottom: 0;
  border-radius: 12px;
  border: 1px solid #e6e6e8;
}

.tcc-dropdown-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tcc-dropdown-container select {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 12px;
  border: 1px solid #e6e6e8;
}

.tcc-donut-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #e6e6e8;
  border-radius: 12px;
  padding: 24px;
}

.tcc-donut-title {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1a1a1c;
  margin-bottom: 40px;
}

.tcc-open-modal {
  margin-left: 180px;
}

.tcc-chart-and-legend {
  display: flex;
  align-items: center;
}

.tcc-donut {
  width: 250px;
  height: 250px;
}

.tcc-legend-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.tcc-legend-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.tcc-legend-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.tcc-legend-color-box {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.tcc-value,
.tcc-percentage-bar,
.tcc-percentage-text {
  display: inline-block;
  vertical-align: middle;
}

.tcc-value {
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
}

.tcc-percentage-bar {
  width: 100px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-right: 10px;
}

.tcc-percentage-fill {
  height: 100%;
  border-radius: 5px;
}

.tcc-percentage-text {
  color: #333;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7B7B86;
}

.tcc-analytic-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  overflow: auto;
  padding-bottom: 20px;
}

.tcc-analytic-table th,
.tcc-analytic-table td {
  border: none;
  padding: 16px;
  text-align: left;
}

.tcc-analytic-table th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.tcc.filter-modal {
  position: relative;
  top: 13%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  left: 26%;
}

/* Custome Style */

.tooltip-icon {
  margin-left: 8px;
}

.dolar-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 16px;
  color: #7B7B86;
}