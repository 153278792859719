.fallback-management {
  width: 100%;
}

.page-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.top-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  gap: 16px;
}

.toggle-buttons {
  min-width: fit-content;
  padding: 4px;
  display: flex;
  height: 42px;
  gap: 8px;
  border: 1px solid #CBCBCF;
  /* Adjust border color and width as needed */
  border-radius: 100px;
  overflow: hidden;
  /* Clip overflowing content */
}

.toggle-button {
  white-space: nowrap;
  /* Make buttons expand to fill available space */
  padding: 12px 24px;
  border: none;
  /* Remove individual button borders */
  border-radius: 0;
  /* Remove individual button borders */
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  color: #7B7B86;
}

.toggle-button.active {
  background-color: #ed1c24;
  color: #fff;
  border-radius: 100px;
  /* Add border for active button */
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.action-button {
  height: 48px;
  border-radius: 100px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
  color: #7B7B86;
  font-size: 16px;
  line-height: 24px;
}

.action-button.export {
  background-color: #ed1c24;
  color: #fff;
}

.action-button .date-icon {
  margin-right: 8px;
}

.action-button .filter-icon {
  margin-left: 8px;
}

.action-button .export-icon {
  margin-right: 8px;
}

.analytics {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  margin-top: 16px;
  overflow: auto;
}

.analytics th,
.analytics td {
  border: none;
  padding: 16px;
  text-align: left;
}

.analytics th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}
.wrap {
  white-space: normal;
}

.min-width {
  min-width: 137px;
}

/* Styles for Overview component */
.overview {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  margin-top: 16px;
  overflow: auto;
  padding-bottom: 20px;
}

.overview th,
.overview td {
  border: none;
  padding: 16px;
  text-align: left;
}

.overview th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.menu {
  position: absolute;
  margin-top: 8px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  width: max-content;
  gap: 4px;
  padding: 8px;
}

.menu-option {
  height: 40px;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  /* Vertically center the text */
  justify-content: flex-start;
  /* Horizontally align the text to the left */
}

.menu-option:hover {
  background-color: rgba(237, 28, 36, 0.1);
  color: #ed1c24;

  .menu-icon {
    stroke: #ed1c24;
    fill: #ed1c24;
  }
}

.menu-icon {
  stroke: #7B7B86;
  fill: #7B7B86;
  margin-right: 8px;
}

/* Styles for sample question modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 3000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-title {
  text-align: center;
  margin-bottom: 10px;
  color: #1a1a1c;
  margin-top: 0;
  font-size: 24px;
  line-height: 32px;
}

.modal-separator {
  border: 0;
  border-top: 1px solid #E6E6E8;
  margin: 10px 0;
  width: 100%;
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  border: solid 1px #E6E6E8;
  padding: 24px;
  border-radius: 12px;
}

.modal-form-group label {
  display: block;
  margin-bottom: 5px;
  color: #1a1a1c;
  font-size: 16px;
  line-height: 24px;
}

.modal-form-group input,
.modal-form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 20px;
}

.suggested-cap-input {
  color: #CBCBCF;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 25px;
}

.modal-buttons button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 83px;
  height: 48px;
  border-radius: 100px;
}

.modal-buttons button[type="button"] {
  background: #fff;
  border: 1px solid #CBCBCF;
}

.modal-buttons button[type="submit"] {
  background: #ed1c24;
  color: white;
}

/* FilterModal*/
.filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.2);
  /* Lightly dimmed background */
  z-index: 3000;
  /* Ensure the modal is on top */
}

.filter-modal {
  position: relative;
  top: 17%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 400px;
  left: 23%;
}

.filter-title {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.filter-separator {
  border: none;
  border-top: 1px solid #CBCBCF;
  margin: 10px 0;
}

.filter-form {
  display: flex;
  flex-direction: column;
}

.filter-form-group {
  margin-bottom: 10px;
}

.filter-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.filter-form-group select,
.filter-form-group input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #CBCBCF;
}

.filter-form-group option input::placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.filter-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 25px;
}

.clear-button {
  background: #fff;
  border: 1px solid #CBCBCF;
  padding: 12px 24px;
  border-radius: 100px;
}

.apply-button {
  background: #ed1c24;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 100px;
}

.analytics-filter-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3000;
}

.analytics-filter-modal {
  position: relative;
  top: 17%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 768px;
}

.analytics-filter-title {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
}

.analytics-filter-separator {
  border: none;
  border-top: 1px solid #CBCBCF;
  margin: 10px 0;
}

.analytics-filter-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.analytics-filter-form-group {
  margin-bottom: 0;
}

.analytics-filter-form-group.full-width {
  grid-column: span 2;
}

.analytics-filter-form-group {
  margin-bottom: 10px;
}

.analytics-filter-form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.analytics-filter-form-group select,
.analytics-filter-form-group input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #CBCBCF;
}

.analytics-filter-form-group option input::placeholder {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.analytics-filter-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 25px;
  grid-column: span 2;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  height: 40px;
  width: 40px;
  margin: 5px;
  padding: 12px;
  border: 1px solid #cbcbcf;
  border-radius: 100px;
  cursor: pointer;
}

.pagination button.active {
  border: 1px solid #7B7B86;
}

.status-box {
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  display: inline-block;
}

.status-box.success {
  background-color: #D0FBB3;
  color: #1E4404;
}

.status-box.error {
  background-color: #FBB3B9;
  color: #44040A;
}

.success-message h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #1A1A1C;
  margin-top: 20px;
  margin-bottom: 10px;
}

.success-message p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #7B7B86;
}

.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}

/* Filter Modal Style */
.fallback-management .fallback-filter {
  position: relative;
}

.fallback-management .fallback-filter .fallback-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 3000;
}

.fallback-management .fallback-filter .fallback-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.fallback-management .fallback-filter .fallback-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.fallback-management .fallback-filter .fallback-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.fallback-management .fallback-filter .fallback-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.fallback-management .fallback-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.fallback-management .fallback-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.fallback-management .fallback-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.fallback-management .fallback-filter .fallback-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.fallback-management .fallback-filter .fallback-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

/* Filter Modal Style */
.fallback-management .analytic-filter {
  position: relative;
}

.fallback-management .analytic-filter .analytic-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 500px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 3000;
}

.fallback-management .analytic-filter .analytic-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.fallback-management .analytic-filter .analytic-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.fallback-management .analytic-filter .analytic-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.fallback-management .analytic-filter .analytic-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.fallback-management .analytic-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.fallback-management .analytic-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.fallback-management .analytic-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.fallback-management .analytic-filter .analytic-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.fallback-management .analytic-filter .analytic-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}