.ue-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ue-header-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: left;
}

.ue-filter-container {
    display: flex;
    align-items: center;
    flex-basis: fit-content;
}

.ue-filter-container .ue-filter-button {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 10px;
    padding: 12px 24px 12px 12px;
    height: 48px;
    gap: 8px;
    border-radius: 100px;
    border: 1px solid #cbcbcf;
    background-color: #fff;
    color: #1a1a1c;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
}

.ue-section-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.ue-section-chart-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
  
  @media only screen and (max-width: 1080px) {
    .ue-section-chart-container {
      display: block;
    }
  }
  

.ue-section-container .grow {
    flex-grow: 1;
}

.ue-section {
    border: 1px solid #e6e6e8;
    padding: 24px;
    border-radius: 12px;
    margin-top: 24px;
}

.ue-section .section-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: #1a1a1c;
    margin-bottom: 24px;
}

.ue-section.w-400 {
    width: 400px;
}

.ue-top-queries {
    border: 1px solid #e6e6e8;
    padding: 16px;
    border-radius: 12px;
    margin-top: 24px;
}

.ue-boxes {
    display: flex;
    gap: 16px;
}

.ue-boxes-grid {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    gap: 16px;
}

@media only screen and (max-width: 1280px) {
    .ue-boxes-grid {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
    }
}

.ue-box {
    margin: 0;
    border: 1px solid #e6e6e8;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    gap: 12px;
    align-content: center;
    width: 100%;
}

.ue-top-queries-box {
    width: 100%;
    margin: 0;
    border: 1px solid #e6e6e8;
    padding: 16px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    gap: 12px;
    align-content: center;
}

.ue-top-queries-box .box-title {
    font-size: 12px;
    line-height: 16px;
    color: #1a1a1c;
    margin: 0;
    display: inline-flex;
    align-items: center;
}

.ue-top-queries-box .box-value-sm {
    font-size: 20px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    margin-bottom: 36px;
    text-align: left;
    vertical-align: top;
    color: #1a1a1c;
}

.ue-top-queries-box .box-value {
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    vertical-align: top;
    color: #1a1a1c;
    margin: 0;
    padding: 0;
}

.ue-box.w-280 {
    width: 280px;
}

.ue-filter-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
}

.ue-select-form {
    font-size: 14px;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid #e6e6e8;
}

.ue-box .box-title {
    font-size: 12px;
    line-height: 16px;
    color: #1a1a1c;
    margin: 0;
    display: inline-flex;
    align-items: center;
}

.ue-box .box-value {
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    vertical-align: top;
    color: #1a1a1c;
    margin: 0;
}

.ue-box .box-value-sm {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    vertical-align: top;
    color: #1a1a1c;
    margin: 0;
}

.ue-box .box-sub-value {
    font-size: 14px;
    color: #1a1a1c;
    margin: 0;
}

.ue-box .box-value .time {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: #1a1a1c;
}

.ue-box .box-date {
    margin: 0;
    color: #7b7b86;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}

.ue-box .box-date .percentage {
    margin-right: 4px;
}

.ue-box .box-date .plus {
    color: green;
}

.ue-box .box-date .minus {
    color: red;
}

.tooltip-icon {
    margin-left: 8px;
}

.share-icon {
    margin-left: auto;
}

.ue-chart-container {
    width: 99%;
}

.chart-legend-container {
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
}

.chart-legend {
    display: flex;
    gap: 4px;
    align-items: center;
}

.chart-legend .legend-title {
    font-size: 12px;
    margin: 0;
}

.chart-legend .legend-blue {
    width: 16px;
    height: 16px;
    background-color: #71AEEB;
    border-radius: 4px;
    display: inline-flex;
}

.chart-legend .legend-red {
    width: 16px;
    height: 16px;
    background-color: #E43936;
    border-radius: 4px;
    display: inline-flex;
}

.filter-container {
    position: relative;
}

.filter-content {
    position: absolute;
    background-color: white;
    border: 1px solid #CBCBCF;
    border-radius: 16px;
    width: 768px;
    right: 0;
    margin-top: 16px;
    padding: 16px
}

.filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex-grow: 1;
    margin: 0;
}

.filter-close {
    background-color: white;
    border: 0;
}

.filter-content .filter-footer {
    display: flex;
    justify-content: end;
    gap: 8px;
}

.ue-form-group {
    display: flex;
}

.btn-reset {
    border: 0;
    margin: 0;
    background-color: white;
    padding-left: 0px;
    cursor: pointer;
}

.btn-primary-sm {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #FFFBFA;
    font-size: 14px;
    line-height: 20px;
    background-color: #ED1C24;
    border-radius: 100px;
    border: 0;
}

.hidden {
    display: none;
}

.btn-tertiary-sm {
    height: 36px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #1A1A1C;
    font-size: 14px;
    line-height: 20px;
    background-color: #FFFFFF;
    border-radius: 100px;
    border: 1px solid #CBCBCF;
}

.btn-tertiary-sm:hover,
.btn-primary-sm:hover {
    opacity: 80%;
}

/* Active User Modal */
.ue-active-user {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
}

.ue-active-user.open {
    display: flex;
}

.ue-active-user__separator {
    margin: 10px 0;
    border-bottom: 1px solid #CBCBCF;
}

.ue-active-user__content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 1200px;
    text-align: center;
}

.ue-active-user__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.ue-active-user__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.ue-active-user__btn-close {
    background: none;
    border: none;
    cursor: pointer;
}

.ue-active-user__section {
    padding-right: 8px;
    max-height: 600px;
    overflow: auto;
}

.ue-active-user__box {
    border: 1px solid #CBCBCF;
    border-radius: 8px;
    padding-bottom: 10px;
    overflow: hidden;
}

.ue-active-user__table {
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    overflow-x: scroll;
    text-wrap: wrap;
}

.ue-active-user__table th,
.ue-active-user__table td {
    padding: 16px;
    border-bottom: 1px solid #E6E6E8;
    text-align: left;
    height: auto;
}

.ue-active-user__table th {
    background-color: #F3F3F4;
    font-weight: 600;
}

.ue-active-user__pagination {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
}

.ue-active-user__pagination button {
    width: auto;
    height: 36px;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid #CBCBCF;
    background-color: #fff;
}

/* Average Session Length Modal */
.ue-avg-session-length {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
}

.ue-avg-session-length.open {
    display: flex;
}

.ue-avg-session-length__separator {
    margin: 10px 0;
    border-bottom: 1px solid #CBCBCF;
}

.ue-avg-session-length__content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 1200px;
    text-align: center;
}

.ue-avg-session-length__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.ue-avg-session-length__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.ue-avg-session-length__btn-close {
    background: none;
    border: none;
    cursor: pointer;
}

.ue-avg-session-length__section {
    padding-right: 8px;
    max-height: 600px;
    overflow: auto;
}

.ue-avg-session-length__box {
    border: 1px solid #CBCBCF;
    border-radius: 8px;
    padding-bottom: 10px;
    overflow: hidden;
}

.ue-avg-session-length__table {
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    overflow-x: scroll;
    text-wrap: wrap;
}

.ue-avg-session-length__table th,
.ue-avg-session-length__table td {
    padding: 16px;
    border-bottom: 1px solid #E6E6E8;
    text-align: left;
    height: auto;
}

.ue-avg-session-length__table th {
    background-color: #F3F3F4;
    font-weight: 600;
}

.ue-avg-session-length__pagination {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
}

.ue-avg-session-length__pagination button {
    width: auto;
    height: 36px;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid #CBCBCF;
    background-color: #fff;
}

/* Retention Rate Modal */
.ue-retention-rate {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
}

.ue-retention-rate.open {
    display: flex;
}

.ue-retention-rate__separator {
    margin: 10px 0;
    border-bottom: 1px solid #CBCBCF;
}

.ue-retention-rate__content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 1200px;
    text-align: center;
}

.ue-retention-rate__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.ue-retention-rate__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.ue-retention-rate__btn-close {
    background: none;
    border: none;
    cursor: pointer;
}

.ue-retention-rate__section {
    padding-right: 8px;
    max-height: 600px;
    overflow: auto;
}

.ue-retention-rate__box {
    border: 1px solid #CBCBCF;
    border-radius: 8px;
    padding-bottom: 10px;
    overflow: hidden;
}

.ue-retention-rate__table {
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    overflow-x: scroll;
    text-wrap: wrap;
}

.ue-retention-rate__table th,
.ue-retention-rate__table td {
    padding: 16px;
    border-bottom: 1px solid #E6E6E8;
    text-align: left;
    height: auto;
}

.ue-retention-rate__table th {
    background-color: #F3F3F4;
    font-weight: 600;
}

.ue-retention-rate__pagination {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
}

.ue-retention-rate__pagination button {
    width: auto;
    height: 36px;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid #CBCBCF;
    background-color: #fff;
}

/* Churn Rate Modal */
.ue-churn-rate {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3000;
}

.ue-churn-rate.open {
    display: flex;
}

.ue-churn-rate__separator {
    margin: 10px 0;
    border-bottom: 1px solid #CBCBCF;
}

.ue-churn-rate__content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 1200px;
    text-align: center;
}

.ue-churn-rate__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.ue-churn-rate__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    flex-grow: 1;
    text-align: center;
    margin: 0;
}

.ue-churn-rate__btn-close {
    background: none;
    border: none;
    cursor: pointer;
}

.ue-churn-rate__section {
    padding-right: 8px;
    max-height: 600px;
    overflow: auto;
}

.ue-churn-rate__box {
    border: 1px solid #CBCBCF;
    border-radius: 8px;
    padding-bottom: 10px;
    overflow: hidden;
}

.ue-churn-rate__table {
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    overflow-x: scroll;
    text-wrap: wrap;
}

.ue-churn-rate__table th,
.ue-churn-rate__table td {
    padding: 16px;
    border-bottom: 1px solid #E6E6E8;
    text-align: left;
    height: auto;
}

.ue-churn-rate__table th {
    background-color: #F3F3F4;
    font-weight: 600;
}

.ue-churn-rate__pagination {
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 10px;
    padding-right: 10px;
}

.ue-churn-rate__pagination button {
    width: auto;
    height: 36px;
    padding: 8px 16px;
    border-radius: 100px;
    border: 1px solid #CBCBCF;
    background-color: #fff;
}

/* Filter Modal Style */
.user-engagement .ue-filter {
    position: relative;
}

.user-engagement .ue-filter .ue-filter__wrapper {
    position: absolute;
    padding: 20px;
    margin-top: 8px;
    right: 0;
    border: solid 1px #CBCBCF;
    border-radius: 8px;
    background-color: #ffff;
    width: 540px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    z-index: 3000;
}

.user-engagement .ue-filter .ue-filter__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.user-engagement .ue-filter .ue-filter__title {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    flex-grow: 1;
    margin: 0;
}

.user-engagement .ue-filter .ue-filter__separator {
    margin: 10px 0;
    border-bottom: 1px solid #CBCBCF;
}

.user-engagement .ue-filter .ue-filter__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
}

.ue-filter__form-row {
    display: flex;
    justify-content: space-between;
}

.ue-filter__form-group {
    flex: 0 0 48%;
    /* Adjust width as necessary */
}

.ue-filter__input-label {
    display: block;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.user-engagement .ue-filter .ue-filter__close {
    background-color: white;
    border: 0;
    cursor: pointer;
}

.user-engagement .ue-filter .ue-filter__footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}