.metrics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.metrics-header h1 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.metrics-upload-button {
  display: flex;
  align-items: center;
  width: 211px;
  height: 48px;
  padding: 12px 24px 12px 12px;
  gap: 10px;
  border: none;
  border-radius: 100px;
  background: #ed1c24;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.download-button,
.filter-button {
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  background-color: #fff;
  color: #1a1a1c;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;
}

.metrics-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.metrics-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #e6e6e8;
  border-radius: 10px;
  margin-top: 16px;
  overflow: auto;
  padding-bottom: 20px;
}

.metrics-table th,
.metrics-table td {
  border: none;
  padding: 16px;
  text-align: left;
}

.metrics-table th {
  background-color: #F3F3F4;
  font-weight: 600;
  white-space: nowrap;
  color: #7B7B86;
}

.metrics-table td .incomplete-label {
  color: #ed1c24;
  margin-right: 0.25rem;
}

.metrics-menu {
  position: absolute;
  margin-top: 8px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;
  height: 108px;
  width: 145px;
  gap: 4px;
  padding: 8px;
}

.metrics-menu-option {
  height: 40px;
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.metrics-menu-option:hover {
  background-color: rgba(237, 28, 36, 0.1);
  color: #ed1c24;

  .metrics-menu-icon {
    stroke: #ed1c24;
  }
}

.metrics-menu-icon {
  stroke: #7B7B86;
  margin-right: 8px;
}

.metrics.filter-modal {
  position: relative;
  top: 21%;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 600px;
  left: 26%;
}

.select__control--is-focused {
  border-color: #ed1c24 !important;
  box-shadow: 0 0 0 1px #ed1c24 !important;
}

.select__option--is-selected {
  background-color: #ed1c24 !important;
  color: white !important;
}

.select__option--is-focused {
  background-color: #ffcccc !important;
}

.select__multi-value {
  background-color: #ed1c24 !important;
}

.select__multi-value__label {
  color: white !important;
}

.select__multi-value__remove:hover {
  background-color: darkred !important;
  color: white !important;
}

.filter-form-row {
  display: flex;
  justify-content: space-between;
}

.filter-form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.multi-select {
  width: 100%;
}

.filter-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.download-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}

.download-modal.open {
  display: flex;
}

.download-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 400px;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.download-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.download-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.file-download-section {
  margin: 20px 0;
}

.file-download-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.file-name {
  color: #2e2eff;
}

.download-icon-button {
  background: transparent;
  border: none;
  cursor: pointer;
}

.download-all-section {
  margin-top: 20px;
}

.download-all-button {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  border-radius: 100px;
  background-color: white;
  border: 1px solid #cbcbcf;
  color: #1a1a1c;
}

/* .download-all-button:hover {
    background-color: #ffcccc;
  } */

.upload-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3001;
}

.upload-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  position: relative;
  text-align: center;
}

.upload-modal-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-modal-separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.file-upload-section {
  margin-top: 20px;
  text-align: left;
}

.file-upload-label {
  font-size: 16px;
  font-weight: 500;
}

.file-upload-input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.file-upload-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-upload-hidden-input {
  display: none;
}

.file-upload-button {
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  background-color: #ed1c24;
  color: white;
  border: none;
  border-radius: 4px;
}

.file-upload-warning {
  color: #7B7B86;
  font-size: 12px;
  margin-top: 5px;
}

.metric-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}

.back-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
}

.metric-details-title {
  margin: 0;
  font-size: 24px;
  flex-grow: 1;
  color: #1a1a1c;
  font-weight: 500;
}

.metric-edit-button {
  width: 142px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  cursor: pointer;
  background-color: #ed1c24;
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border: none;
}

.metric-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #1a1a1c;
  margin: 0;
  margin-bottom: 16px;
}

.metric-details-container {
  display: flex;
  flex-wrap: wrap;
}

.metric-details-metric {
  flex: 0 0 calc(29% - 10px);
  margin: 7.5px;
  padding: 16px;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
}

.description {
  flex: 0 0 calc(95% - 10px);
  padding: 16px;
  border: 1px solid #e6e6e8;
  border-radius: 8px;
}

.metrics-detail-separator {
  border: 1px solid #e6e6e8;
}

.edit-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 3000;
}

.edit-modal.open {
  display: flex;
}

.edit-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 700px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.edit-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.edit-separator {
  border: none;
  border-top: 1px solid #CBCBCF;
  margin-bottom: 20px;
}

.edit-section {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid #e6e6e8;
  gap: 24px;
  overflow: auto;
  max-height: 600px;
}

@media only screen and (min-height: 968px) {
  .edit-section {
    max-height: 700px;
  }
}

@media only screen and (min-height: 1080px) {
  .edit-section {
    max-height: 800px;
  }
}

.edit-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.edit-name {
  font-size: 16px;
  font-weight: 500;
}

.edit-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.edit-input[readonly] {
  background-color: #e6e6e8;
  color: #7B7B86;
  cursor: not-allowed;
}

.edit-sample-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.edit-sample-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7;
  padding: 8px;
  margin-bottom: 5px;
  border-radius: 4px;
}

.add-more-container {
  display: flex;
  justify-content: flex-end;
  /* Aligns the button to the right */
}

.add-more-button {
  color: #1a1a1c;
  background-color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 101px;
  height: 36px;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
}

.add-more-button:hover {
  background-color: #0056b3;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.trash-icon {
  width: 16px;
  height: 16px;
}

.edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.confirmation-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
}

.confirmation-subtext {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #7b7b86;
}

/* Filter Style */
.metric .metric-filter {
  position: relative;
}

.metric .metric-filter .metric-filter__wrapper {
  position: absolute;
  padding: 20px;
  margin-top: 8px;
  right: 0;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  background-color: #ffff;
  width: 590px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
  z-index: 1;
}

.metric .metric-filter .metric-filter__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.metric .metric-filter .metric-filter__title {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  flex-grow: 1;
  margin: 0;
}

.metric .metric-filter .metric-filter__separator {
  margin: 10px 0;
  border: 0;
  height: 1px;
  background-color: #CBCBCF;
}

.metric .metric-filter .metric-filter__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.metric-filter__form-row {
  display: flex;
  justify-content: space-between;
}

.metric-filter__form-group {
  flex: 0 0 48%;
  /* Adjust width as necessary */
}

.metric-filter__input-label {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.metric .metric-filter .metric-filter__close {
  background-color: white;
  border: 0;
  cursor: pointer;
}

.metric .metric-filter .metric-filter__footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}