.main-container {
  height: calc(100vh - 32px);
  display: flex;
  background-color: #E6E6E8;
  padding: 16px;
  gap: 16px;
}

.side-panel {
  min-width: 256px;
  max-width: 256px;
  height: calc(100% - 32px);
  transition: all 0.3s ease;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  border-radius: 12px;
  overflow-y: auto;
}

.side-panel.closed {
  min-width: 48px;
  max-width: 48px;
  align-items: center;
  overflow-y: visible;
}

.content-area {
  height: auto;
  flex-grow: 1;
  transition: margin-left 0.3s ease;
  padding: 24px;
  overflow: auto;
  border-left: 0;
  background: #fff;
  border-radius: 12px;
}

.side-panel .logo {
  height: 60px;
  align-self: flex-start;
}

.side-panel.closed .logo {
  height: 24px;
  align-self: center;
}

.separator {
  height: 1px;
  background-color: #CBCBCF;
  margin: 20px 0;
  width: 48px;
}

.tabs {
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.side-panel.closed .tabs {
  align-items: center;
  width: 48px;
}

.bottom-tabs {
  display: flex;
  flex-direction: column;
}

.side-panel.closed .bottom-tabs {
  align-items: center;
  width: 48px;
}

.tab {
  width: 100%;
  padding: 16px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
  height: 48px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* justify-content: left; */
  color: #4A4A51;
  transition: all 0.3s ease;
  justify-content: space-between;
}

.bottom-tab {
  width: 256px;
  height: 48px;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* justify-content: left; */
  color: #4A4A51;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.tab-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.rotate {
  transform: rotate(180deg);
}

.side-panel.closed .rotate {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.side-panel.closed .tab {
  width: 48px;
  justify-content: center;
}

.tab-icon {
  stroke: #4A4A51;
}

.fill {
  fill: #4A4A51;
}

.tab-name {
  font-size: 14px;
  margin: 0;
  line-height: 24px;
  font-weight: 500;
}

.tab.active {
  background-color: #ed1c24;
  color: #fff;
  line-height: 48px;
}

.tab.active .tab-icon {
  stroke: #fff;
}

.tab.active .fill {
  fill: #fff;
}

.tab:hover {
  background-color: #E6E6E8;
}

/* Icon */
.sort-icon {
  margin-top: 2px;
}

.user-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: solid;
}

.sign-out {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #ed1c24;
}

.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #f0f0f0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #1a1a1c;
  padding: 15px;
  border-radius: 5px;
  z-index: 1002;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.notification.success {
  background-color: #eefde3;
}

.notification.error {
  background-color: #fde8e8;
}

.notification .close-icon {
  cursor: pointer;
}

/* Upload Modal */
.upload-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.upload-modal-buttons .upload-modal-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
}

.upload-modal-button.cancel-button {
  background-color: #fff;
  color: #1a1a1c;
  border-radius: 100px;
  width: 98px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  cursor: pointer;
}

.upload-modal-button.submit-button {
  background-color: #ed1c24;
  color: white;
  border-radius: 100px;
  width: 98px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  cursor: pointer;
}

.upload-modal-button.submit-button:hover {
  background-color: #ED1C24;
}

.upload-modal-button.submit-button:disabled {
  background-color: #cbcbcf;
  cursor: not-allowed;
}

/* Edit Modal */
.edit-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.edit-button.cancel-button {
  background-color: #fff;
  color: #1a1a1c;
  border-radius: 100px;
  width: 98px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  cursor: pointer;
}

.edit-button.cancel-button:hover {
  background-color: #f0f0f0;
}

.edit-button.submit-button {
  background-color: #ed1c24;
  color: white;
  border-radius: 100px;
  width: 98px;
  height: 48px;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid #cbcbcf;
  cursor: pointer;
}

.edit-button.submit-button:hover {
  background-color: #ED1C24;
}

.edit-button.submit-button:disabled {
  background-color: #cbcbcf;
  cursor: not-allowed;
}

.table-wrapper {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tr {
  border-bottom: 1px solid #E6E6E8;
}

td {
  font-size: 13px;
  color: #7B7B86;
}

th {
  font-weight: 500;
  font-size: 13px;
  color: #4A4A51;

}

/* Text Style */
.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left;
}

/** Buttton **/
.btn-calendar-apply {
  width: 100%;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #ED1C24;
  border-radius: 100px;
  border: 0;
  color: #FEF1F1;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
}

.btn-calendar-clear {
  width: 100%;
  height: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #FFFFFF;
  border: 1px solid #CBCBCF;
  border-radius: 100px;
  color: #1A1A1C;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
}

.btn-base-sm {
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
  cursor: pointer;
}

.btn-base-sm.font-base {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #7B7B86;
  text-align: center;
}

.btn-base {
  height: 42px;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
}

.btn-base:hover {
  cursor: pointer;
  opacity: 0.8;
}

.btn-base.font-base {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #7B7B86;
  text-wrap: nowrap;
}

.btn-base.with-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 24px;
}

.btn-base.with-icon-r {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 24px;
}

.btn-primary {
  height: 42px;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #ed1c24;
  background-color: #ed1c24;
  cursor: pointer;
}

.btn-primary:hover {
  cursor: pointer;
  opacity: 0.8;
}

.btn-primary:disabled {
  border: 1px solid #CBCBCF;
  background-color: #CBCBCF;
  cursor: default;
}

.btn-disabled {
  height: 48px;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #CBCBCF;
  background-color: #CBCBCF;
}

.btn-disabled.font-disabled {
  font-size: 16px;
  font-weight: 500;
  color: #7B7B86;
}

.btn-primary.font-primary {
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-wrap: nowrap;
}

.btn-primary.font-primary:disabled {
  font-size: 14px;
  font-weight: 500;
  color: #1A1A1C;
}

.btn-primary.with-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 24px;
}

.btn-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 42px;
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid #CBCBCF;
  background-color: #fff;
  color: #7B7B86;
  font-size: 16px;
  line-height: 24px;
}

.btn-date.large {
  width: 248px;
}

/* Dropdown Calendar */

.dropdown-calendar {
  position: relative;
}

.date-modal {
  position: absolute;
  right: 0;
  top: 56px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbcbcf;
  z-index: 1000;
}

.date-modal-content {
  padding: 16px;
  width: 160px;
  min-height: 187px;
}


.date-modal-content.large {
  width: 212px;
  min-height: 187px;
}

.date-modal-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.date-modal-content li {
  padding: 8px 0;
}

/* Tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext--sm {
  width: 200px;
}

.tooltip .tooltiptext--md {
  width: 160px;
}

.tooltip .tooltiptext--lg {
  width: 200px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #1A1A1C;
  color: #fff;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  text-align: center;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip-top {
  bottom: 125%;
  translate: -50%;
}

.tooltip-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: #1A1A1C transparent transparent transparent;
}

.edit-input textarea {
  resize: vertical;
}

/* Prompt Management - metrics */

.metrics-detail-description {
  margin: 0;
  padding-top: 8px;
  color: #7B7B86;
}

.metric-details-metric-name {
  margin: 0;
}

/* Promp Management - Properties */
.overview-box-title {
  margin: 0;
}

.overview-description {
  padding-top: 8px;
  color: #7B7B86;
}

/* Form Checkbox */
.form-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;

}

.form-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 1px solid #cbcbcf;
  border-radius: 4px;
}

.form-checkbox:hover input~.checkmark {
  background-color: #cbcbcf;
  border: 1px solid #cbcbcf;
}

.form-checkbox input:checked~.checkmark {
  background-color: #ED1C24;
}

.form-checkbox input:disabled~.checkmark {
  background-color: #cbcbcf;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-checkbox input:checked~.checkmark:after {
  display: block;
}

.form-checkbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 7.5px;
  border: solid white;
  border-width: 0px 2px 2px 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Flex */
.group-flex-2 {
  display: flex;
  gap: 2px;
}

.group-flex-8 {
  display: flex;
  gap: 8px;
}

.group-flex-16 {
  display: flex;
  gap: 16px;
}

.group-grid-16 {
  display: grid;
  gap: 16px;
}

/* Flex Justify */
.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

/* Flex Align */
.align-center {
  align-items: center;
}

/* Grid */
.group-grid-16.col-2 {
  grid-auto-flow: column;
}

/* Separator */
.separator-line {
  border-left: 0.5px solid #cbcbcf;
}

/* Cursor */

.cursor-pointer {
  cursor: pointer;
}

/* Loading Component Style */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.loading-container .loading-message {
  margin-left: 8px;
  font-size: 14px;
  color: #7B7B86;
  font-weight: 400;
}

/* Loading Skeleton */
.loading-skeleton {
  background: #E6E6E8;
  background: linear-gradient(110deg, #E6E6E8 8%, #F3F3F4 18%, #E6E6E8 33%);
  padding: 2px;
  border-radius: 4px;
  background-size: 200% 100%;
  animation: skeleton-loading 1.5s linear infinite;
}

@keyframes skeleton-loading {
  to {
    background-position-x: -200%;
  }
}

@keyframes skeleton-loading-2 {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 0.8;
  }
}


/* Loading Chart */
.loading-chart {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #E6E6E8;
  opacity: 0.9;
  border-radius: 8px;
}

/* Margin style */
.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

/** Datepicker **/

.date-picker-container {
  background-color: white;
  padding: 8px;
  border: solid 1px #CBCBCF;
  border-radius: 8px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.react-datepicker__month-container {
  float: none !important;
}

.react-datepicker__day {
  width: 30px !important;
  height: 30px !important;
  padding: 2px;
}

.react-datepicker-popper {
  line-height: normal !important;
}

.react-datepicker__day-name {
  width: 30px;
  height: 30px;
  padding: 3.5px;
  margin: 0;
}

/* Change background color when selecting. */
.react-datepicker__day--keyboard-selected:not(.react-datepicker__day--range-start) {
  /* Change this to your desired border radius */
  background-color: #FBB3B9;
  color: white;
  z-index: -1 !important;
}

/* Change background color when hovering. */
.react-datepicker__day:hover {
  background-color: #FBB3B9 !important;
  /* Change this to your desired color */
  color: #101828;
  /* Change this to your desired color */
  border-radius: 0px;
}

.react-datepicker__day--in-range {
  background-color: #FBB3B9 !important;
  border-radius: 8px;
  color: #101828 !important;
  margin-top: 5px;
}

/* Change background color when selecting. */
.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
  background-color: #ED1C24 !important;
  /* Change this to your desired color */
  color: white !important;
  /* Change this to your desired color */
  position: relative !important;
}




.react-datepicker__day--range-start::before {
  left: -1px;
}

.react-datepicker__day--range-end::before {
  left: -2px;
}

.react-datepicker__day--range-start::before {
  margin-left: 0px;
  /* Adjust circle position */
  transform: translate(1px, -50%);
}

.react-datepicker__day--range-end::before {
  margin-left: 13px;
  transform: translate(-10px, -50%);
}

/* Change background color when selecting. */
.react-datepicker__day--in-selecting-range {
  background-color: #FBB3B9 !important;
  /* Change this to your desired color */
  color: #101828;
  border-radius: 0px;
}

.react-datepicker__header {
  background-color: white !important;
  border: none;
}

.react-datepicker__day-disabled {
  color: red;
}

/* Change font family */
.react-datepicker {
  font-family: "Mabry Pro";
  /* Change this to your desired font family */
}

.react-datepicker__day--outside-month {
  color: #888;
  /* Change this to your desired color */
}

.date-picker-top {
  display: flex;
  align-items: center;
  gap: 4px;
}

.date-picker-top p {
  font-size: 14;
  font-weight: 500;
}

.date-picker-header {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
}

.date-picker-header .month-button {
  background-color: white;
  padding: 0;
  border: 0;
}

.calendar-back-button {
  background-color: white;
  padding: 0;
  border: 0;
  margin-top: 0;
}

.calendar-back-button .calender-back-icon {
  margin-top: 3px;
}

.ue-date-modal-content {
  position: relative;
  top: 13%;
  background: white;
  padding: 20px;
  padding-top: 0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 168px;
  height: 187px;
  left: 32%;
}

.ue-date-modal-content ul {
  list-style: none;
  padding: 0;
}

.ue-date-modal-content li {
  padding: 8px 0;
  cursor: pointer;
}

.date-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  text-align: left;
  padding: 8px;
}

.date-picker-footer {
  display: flex;
  gap: 12px;
  margin-top: 8px;
}


/* TopBar Style */
.topbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

/*  */
.profile-container {
  display: flex;
  align-items: center;
  gap: 16px;
}

.profile-container .name-text {
  font-size: 16px;
  font-weight: 500;
}

.profile-container .role-text {
  font-size: 14px;
  font-weight: 400;
  color: #7B7B86;
}

.avatar-container {
  position: relative;
}

.avatar-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #D15A5A;
  vertical-align: baseline;
}

.avatar-icon:hover {
  box-shadow: 0 0 0 8px #e6e6e8;
}

.initials-name {
  position: absolute;
  line-height: 1;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Profile Dropdown */
.profile-dropdown {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbcbcf;
  z-index: 1000;
}

.profile-contents {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  width: 180px;
}

.profile-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
}

.profile-item--red {
  color: #ed1c24;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;
}

.profile-item--red:hover {
  background-color: #e0e0e0;
  border-radius: 8px;
}

.profile-item:hover {
  background-color: #e0e0e0;
  border-radius: 8px;
}

/* BreadCrumbs Style */

.breadcrumb-icon {
  width: 16px;
  height: 16px;
}

.breadcrumb-prev {
  color: #7B7B86;
  font-size: 14px;
}

.breadcrumb-prev.link-style {
  cursor: pointer;
}

.breadcrumb-prev.link-style:hover {
  text-decoration: underline;
}

.breadcrumb-current {
  color: #1a1a1c;
  font-size: 14px;
}


/* Tab */
.tab-wrapper {
  position: relative;
}

.tab-wrapper.close {
  height: 48px;
  width: 48px;
}

/* Sub Tab */

.dropdown {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.sub-tab {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #cbcbcf;
  padding: 8px;
  left: 175%;
  top: 0;
  width: 200px;
  z-index: 2200;
}

.sub-tab-item {
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  border-radius: 8px;
  color: #4A4A51;
}

.sub-tab-item.side-panel-open {
  padding-left: 43px;
}

.sub-tab-item:hover {
  background-color: #E6E6E8;
}

.sub-tab-item.active {
  background-color: #ed1c24;
  color: #fff;
  line-height: 3rem;
}

.sub-tab-name {
  font-size: 0.875rem	;
  margin: 0px;
  margin-left: 8px;
  line-height: 24px;
  text-wrap: nowrap;
}

.kebab-menu-container {
  position: relative;
}

/* Page not found style */
.not-found-container {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  color: #7B7B86;
  font-size: 24px;
}

/* React Select Period Chart */

.select-period-chart__menu-list {
  overflow-y: visible !important;
}

.select-period-chart__control--is-focused {
  border-color: #ed1c24 !important;
  box-shadow: 0 0 0 1px #ed1c24 !important;
}

.select-period-chart__option--is-selected {
  background-color: #ed1c24 !important;
  color: white !important;
}

.select-period-chart__option--is-focused {
  background-color: #ffcccc !important;
}

.select-period-chart__multi-value {
  background-color: #ed1c24 !important;
}

.select-period-chart__multi-value__label {
  color: white !important;
}

.select-period-chart__multi-value__remove:hover {
  background-color: darkred !important;
  color: white !important;
}

/* Toast */
.toast-container {
  position: fixed;
  top: 4rem;
  right: 2rem;
  z-index: 5000;
}

.toast {
  min-width: 16rem;
  padding: 16px;
  margin-bottom: 1rem;
  border-radius: 8px;
  animation: toast-fade-in 0.5s ease-out;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.toast.toast--success {
  background-color: #EEFDE3;
  color: #1A1A1C;
}

.toast.toast--error {
  background-color: #FDE3E5;
  color: #1A1A1C;
}

.toast.toast--warning {
  background-color: #ffcc00;
  color: #1A1A1C;
}

.toast.toast--info {
  background-color: #646cff;
  color: #fff;
}

.toast .toast-close {
  background: none;
  border: none;
  color: #7B7B86;
  cursor: pointer;
  padding: 0;
  height: 24px;
}

.toast .toast-close:focus {
  color: inherit;
  border: none;
}

.toast .toast-header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toast .toast-title {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  width: 100%;
}

.toast .toast-message {
  font-size: 12px;
  text-align: left;
  margin: 0;
  padding-top: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.toast .toast-icon {
  height: 24px;
  width: 24px;
  padding: 0;
  margin: 0;
}

.toast .toast-icon--success {
  color: #EEFDE3;
  background-color: #5ED40D;
  border: none;
  border-radius: 50%;
}

.toast .toast-icon--error {
  color: #ed1c24;
}

/* Width style */

.w-200 {
  width: 200px;
}

.w-300 {
  width: 300px;
}

/* Badge */

.status-badge--success {
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  background-color: #D0FBB3;
  color: #1A1A1C;
}

.status-badge--pending {
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  background-color: #CBCBCF;
  color: #1A1A1C;
}

.status-badge--failed {
  font-size: 14px;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  background-color: #FBB3B9;
  color: #1A1A1C;
}


/* Session Expired Modal */
.session-expired__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.session-expired__modal {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 300px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
}

.session-expired__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.session-expired__title {
  color: #1a1a1c;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  width: 100%;
  margin: 0;
}

.session-expired__separator-line {
  border-bottom: 0.5px solid #cbcbcf;
  margin-top: 10px;
  margin-bottom: 10px;
}

.session-expired__close {
  background-color: white;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.session-expired__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #1a1a1c;
  max-height: 500px;
  overflow: auto;
}

.session-expired__footer {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
}