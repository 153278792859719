.development {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; 
  text-align: center;
}

.development-img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.development-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: #000000;
}
